if (!lemonade && typeof (require) === 'function') {
    var lemonade = require('lemonadejs');
}

;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    global.List = factory();
}(this, (function () {

    const Pagination = function(self) {
        // The current result
        let result = self.data;

        const find = function(o, query) {
            query = query.toLowerCase();
            for (let key in o) {
                let value = o[key];
                if ((''+value).toLowerCase().search(query) >= 0) {
                    return true;
                }
            }
            return false;
        }

        const search = function(str) {
            if (str) {
                // Filter the data
                let t = [];
                if (Array.isArray(self.data)) {
                    t = self.data.filter(function(item) {
                        return find(item, str);
                    });
                }
                // Result
                result = t;
            } else {
                result = self.data;
            }
            // Go back to page zero
            self.page = 0;
        }

        const page = function() {
            if (! self.pagination) {
                self.result = result;
            } else {
                // Pagination
                let p = parseInt(self.pagination);
                let s;
                let f;
                let numOfItems = result.length;
                // Define the range for this pagination configuration
                if (p && numOfItems > p) {
                    s = (p * self.page);
                    f = (p * self.page) + p;

                    if (numOfItems < f) {
                        f = numOfItems;
                    }
                } else {
                    s = 0;
                    f = numOfItems;
                }

                // Change the page
                p = [];
                for (let i = s; i < f; i++) {
                    p.push(result[i]);
                }

                // Set the new results for the view
                self.result = p;

                // Update pagination
                pagination();
            }
        }

        const pagination = function() {
            let pages = [];
            // Update pagination
            if (self.pagination > 0) {
                // Get the number of the pages based on the data
                let n = Math.ceil(result.length / self.pagination);
                if (n > 1) {
                    let s;
                    let f;
                    // Controllers
                    if (self.page < 6) {
                        s = 0;
                        f = n < 10 ? n : 10;
                    } else if (n - self.page < 5) {
                        s = n - 9;
                        f = n;
                        if (s < 0) {
                            s = 0;
                        }
                    } else {
                        s = parseInt(self.page) - 4;
                        f = parseInt(self.page) + 5;
                    }

                    // First page
                    if (s > 0) {
                        pages.push({
                            title: 0,
                            value: '«'
                        });
                    }

                    // Link to each page
                    let i;
                    for (i = s; i < f; i++) {
                        pages.push({
                            title: i,
                            value: i+1,
                            selected: self.page == i
                        });
                    }

                    // Last page
                    if (f < n) {
                        pages.push({
                            title: n - 1,
                            value: '»'
                        });
                    }
                }
            }

            self.pages = pages;
        }

        self.setPage = function(page) {
            if (typeof(page) === 'object') {
                page = page.target.title;
            }
            self.page = parseInt(page);
        }

        // Call back
        return (prop) => {
            if (prop === 'data' || prop === 'input') {
                // Filter the results based on the new data/input
                search(self.input);
                // Dispatch on search event
                Dispatch.call(self, 'onsearch', self);
            } else if (prop === 'page') {
                // Change the page sending the element where the property page is associated
                page();
                // Dispatch onchangepage event
                Dispatch.call(self, 'onchangepage', self);
            }
        }
    }

    // Dispatcher
    const Dispatch = function(type, option) {
        if (typeof this[type] === 'function') {
            this[type](this, option);
        }
    }

    const List = function(children) {
        // Lemonade element
        let self = this;

        // Process of pagination
        const pagination = Pagination(self);

        // Monitor the search
        self.onchange = function(prop) {
            pagination(prop);
        }

        // Apply the pagination after initialization
        self.onload = function() {
            self.page = 0;
        }

        let template = '';

        if (typeof(children) === 'object') {
            self.settings = {
                loop: {
                    result: children
                },
                getRoot: () => {
                    return false;
                }
            }
        } else if (typeof(children) === 'string') {
            template = children;
        }

        return `<div>
            <div class="list-header" :data="self.data">
                <input type='text' :bind="self.input" search="{{self.search}}"/>
                <ul :page="self.page" :loop="self.pages">
                    <li onclick="self.parent.setPage" title="{{self.title}}" selected="{{self.selected}}">{{self.value}}</li>
                </ul>
            </div>
            <div class="list-content" :ref="self.container" :loop="self.result" data-message="{{self.message}}">${template}</div>
        </div>`;
    }

    lemonade.setComponents({ List: List });

    return function (root, options, template) {
        if (typeof (root) === 'object') {
            lemonade.render(List, root, options, template)
            return options;
        } else {
            return List.call(this, root, template)
        }
    }
})));